var cart = new Cart();
/*
 * Requires data-id attribute of product ID
 * You may give the quantity field the id qty-{ID}
 */
var $addToCartBtn = $('.do-add-to-cart');
/*
 * Requires data-key attribute (session key)
 */
var $deleteFromCartBtn = $('.do-delete-from-cart');
/*
 * This is the select dropdowns with variant options
 */
var $variantSelects = $('select.variants');
/*
 * Requires data-id attribute of product ID
 * Add this class to quantity input items within the cart
 */
var $quantityField = $('.do-change-quantity');
/*
 * Coupon code input field
 */
var $couponField = $('.coupon-code');
/*
 * Apply coupon button
 */
var $applyCouponBtn = $('.do-apply-coupon');
/*
 * Coupon row which will be unhidden when coupon applied
 */
var $couponRow = $('.coupon-row');
/*
 * Delete coupon button
 */
var $deleteCouponBtn = $('.do-delete-coupon');
/*
 * Region select
 */
var $regionField = $('.do-update-region');
/*
 * Copy billing to shipping button
 */
var $copyBillingToShippingBtn = $('.do-copy-billing');
/*
 * Billing and shipping forms
 */
var $billingForm = $('.billing-form');
var $newAddressForm = $('.new-address-form');
/*
 * Payment Form (DPS Hosted)
 */
var $paymentForm = $('.payment-form');
/*
 * Apply this class to any items which holds the cart count
 */
var $cartCounters = $('.cart-counter');
/*
 * The cart totals tags in the cart and checkout pages
 */
var totals = {
    subtotal: $('.js-subtotal'),
    coupon_name: $('.js-coupon-name'),
    coupon_amount: $('.js-coupon-amount'),
    shipping: $('.js-shipping'),
    gst: $('.js-gst'),
    total: $('.js-total')
};
/*
 * Add this class to checkout continue buttons so they are hidden during AJAX requests
 */
var $cartHideOnAjax = $('.js-hide-on-ajax');
/*
 * Save shipping options
 */
var $saveShippingOptions = $('.do-save-shipping-options');
/*
 * Save gift card messages
 */
var $saveGiftCardMessages = $('.do-save-gift-card-messages');

$(document).ready(function(){
    $addToCartBtn.click(function(){
        addToCart($(this));
    });
    $quantityField.change(function(){   
      var value = $(this).val();
      if (!isNaN(value)) {
          if (parseInt(value) > 0) {
            updateQuantity($(this));
          } else {
            $(this).val(1);
          }          
      }      
    });
//    $quantityField.keyup(function() {
//        var value = $(this).val();
//        var qty = 1;
//        if (!isNaN(value)) {
//            qty = parseInt(value);            
//            if (qty < 1) {
//                qty = 1;
//            } 
//        }        
//        $(this).val(qty);
//        console.log(qty);
//        //$(this).trigger('change');
//    });
    $deleteFromCartBtn.click(function(){
        deleteCartItem($(this));
    });
    $applyCouponBtn.click(function(){
        applyCoupon($(this));
    });
    $deleteCouponBtn.click(function(){
        removeCoupon();
    });
    $regionField.change(function(){
        changeRegion($(this));
    });
    $regionField.trigger('change');
    $copyBillingToShippingBtn.click(function(){
        copyBillingToShipping($(this));
    });
    $newAddressForm.submit(function(e){
        e.preventDefault();
        saveAddress($(this));        
        return false;
    });    
});

/****************************************************************************************************************************
 CUSTOM - SPLIT DELIVERY STUFF
 ****************************************************************************************************************************/
function saveShippingOptions()
{
    var $group = $('.checkout-delivery-structure-group');
    var $split = $('.checkout-delivery-structure-split');
    var data = {};

    if ($group.css('display') == 'block') {
        // Group shipment - set all items to ship to same address
        data.item = getShippingObject($group);
    } else {
        // Split shipment - set each items address individually
        data.items = [];
        $.each($split.find('.split-delivery-line-item'), function(key, value) {
            data.items[key] = getShippingObject($(value));
        });
    }

    $.post('/rest/cart/saveShipping', data, function(response){
        if (response.status == 'success') {
            cartUpdateHtml(response.cart);
            alert('Success: Assad to do stuff');
        } else {
            alert('An internal error has occurred. Please try refreshing the page and trying again.');
        }
    }, 'json');
}

function getShippingObject($row)
{
    var $container = null;
    var item_key = null;
    if ($row.find('.item-key').length > 0)
        item_key = $row.find('.item-key').val();

    if ($row.find('.item-is-deliver').is(':checked')) {
        // IS DELIVERY
        $container = $row.find('.delivery-form');
        return {
            item_key: item_key,
            type: 'delivery', //todo: Pull this constant from Cart model
            comments: $container.find('.comments').val(),
            address_key: $container.find('.shipping-address').val()
        };
    } else {
        // IS COLLECT
        $container = $row.find('.collect-form');
        return {
            item_key: item_key,
            type: 'collect', //todo: Pull this constant from Cart model
            comments: $container.find('.comments').val(),
            store_id: $container.find('.collect-store').val()
        };
    }
}

/****************************************************************************************************************************
 PAYMENT FUNCTIONS
 ****************************************************************************************************************************/
function sendPayment()
{
    var data = $paymentForm.serializeArray();
    data.push({
        name: 'DateExpiry',
        value: $paymentForm.find('input[name=ExpiryMM]').val() + $paymentForm.find('input[name=ExpiryYY]').val()
    });
    cart.sendPayment(data, function(){
        if (cart.errors != '') {
            //todo: Graceful errors
            alert(cart.errors);
        } else {
            window.location = '/thankyou';
        }
    });
}


/****************************************************************************************************************************
 CART FUNCTIONS
 ****************************************************************************************************************************/
function addToCart($selector)
{
    var variantIds = getVariantIds();
    var qty = parseFloat($('#qty-' + $selector.data('id')).val());
    
    if (!qty || qty == 0)
        qty = 1;

    if (qty > 0) {
        var data = {
            'id': $selector.data('id'),
            'variants': variantIds,
            'qty': qty
        };

        $cartHideOnAjax.hide();
        cart.updateQty(data, function() {
            cartUpdateHtml(cart);
            if (!cart.errors) {
              $('.js-cart-images').empty();
              $.each(cart.items, function(index, obj) {
                $('.js-cart-images').append('<div class="item"><img src="' + obj.heroImage + '" alt="' + obj.title + '" width="45px"></div>');
              });
              
              alert('Item has been added to cart');
            }
        }, true);
    }
}

function updateQuantity($selector)
{
    var itemId = $selector.data('id');
    var qty = parseInt($selector.val());
    
    if (qty < 1) {
      //do nothing
    } else {

      var data = {
          '_token': $('input[name="_token"]').val(),
          'id': itemId,
          'variants': getVariants($selector.data('variants')),
          'qty': qty
      };
  
      $cartHideOnAjax.hide();
      cart.updateQty(data, function(){
        if (cart.errors) {
          window.location.href='/cart';
        }
        cartUpdateHtml(cart);
      });
      
    }
}

function deleteCartItem($selector)
{
    if ($selector.data('key') != '') {
        var key = $selector.data('key');
        $cartHideOnAjax.hide();
        cart.removeItem({key: key}, function() {
            $('#' + key).remove();
            cartUpdateHtml(cart);
        });
    }
}

function saveBilling()
{
    cart.saveBilling($billingForm.serializeArray(), function(){
        if (cart.errors != '') {
            //todo: Graceful errors
            alert(cart.errors);
        } else {
            cartUpdateHtml(cart);
            //todo: Assad to add his class here
            alert('Saved successfully - assad to trigger class');
            //new commonCartCheckout().toggleStep1AddressTrigger(); //custom
        }
    });
}

function saveAddress($form)
{
    cart.saveAddress($form.serializeArray(), function(){
        if (cart.errors != '') {
            //todo: Graceful errors
            alert(cart.errors);
        } else {
            // Reload the page so the new address can be shown
            $billingForm.addClass('open-step');
            $form.removeClass('open-step');
            cartUpdateHtml(cart);
        }
    });
}

/****************************************************************************************************************************
 COUPON FUNCTIONS
 ****************************************************************************************************************************/
function applyCoupon($selector)
{
    var coupon = $couponField.val();
    if (coupon != '') {
        $cartHideOnAjax.hide();
        cart.addCoupon(coupon, function(){
            cartUpdateHtml(cart);
        });
    }
}

function removeCoupon()
{
    $cartHideOnAjax.hide();
    cart.removeCoupon(function(){
        cartUpdateHtml(cart);
    });
}

/****************************************************************************************************************************
 SHIPPING FUNCTIONS
 ****************************************************************************************************************************/
function changeRegion($selector)
{
    if ($selector.val() > 0) {
        $cartHideOnAjax.hide();
        cart.updateShipping($selector.val(), function() {
            cartUpdateHtml(cart);
        });
    } else {
        //todo: Throw some sort of error?
    }
}

function copyBillingToShipping($element)
{
    if($element.is(':checked')) {
      $billingForm.find('input, select').each(function() {
          var name = $(this).attr('name');
          var $field = $newAddressForm.find("[name='"+name+"']");
          
          if($field.length) {
              $(this).val($field.val());
          } 
      });
    } else {
        $billingForm.find('input, select').each(function() {
            this.value = '';
        })
    }
}

/****************************************************************************************************************************
 PRIVATE FUNCTIONS
 ****************************************************************************************************************************/
function cartUpdateHtml(cart)
{
    cartUpdateItems(cart);
    $cartCounters.html(cart.count);
    
    $('.js-cart-gst-text').html('Inclusive');
    if (cart.shippingInfo) {
        if (cart.shippingInfo.country_id == 13) {
            $('.js-cart-gst-text').html('Exclusive');
        }
    }
    $('.js-cart-total-header').html('$' + cart.total.toFixed(2));
    totals.subtotal.html(cart.subtotal.toFixed(2));
    totals.shipping.html(cart.shipping.toFixed(2));
    totals.gst.html(cart.gst.toFixed(2));
    totals.total.html(cart.total.toFixed(2));
    totals.coupon_name.html(cart.coupon_code);
    totals.coupon_amount.html(parseFloat(cart.coupon).toFixed(2));
    if (cart.coupon_code) {
        $couponRow.show();
    } else {
        $couponRow.hide();
    }

    // show different checkout buttons depending on cart-total
    var freight = $('#freight-limit').val();
    var difference = Math.round(freight - cart.total) + 1;
    $('.wrap-title').find('strong').html('$' + difference);

    if (cart.total < freight) {
        $('.freight-wrap .under.checkout.red').addClass('active');
        $('.freight-wrap .over.checkout.red').removeClass('active');
    } else {
        $('.freight-wrap .under.checkout.red').removeClass('active');
        $('.freight-wrap .over.checkout.red').addClass('active');    
    }
    
    $('.js-cart-images').empty();
    $.each(cart.items, function(index, obj) {
      $('.js-cart-images').append('<div class="item"><img src="' + obj.heroImage + '" alt="' + obj.title + '" width="45px"></div>');
    });
    $cartHideOnAjax.show();
}

function cartUpdateItems(cart)
{
    if (cart.items) {
        $.each(cart.items, function(key, value) {
            var $row = $('#' + key);
            if ($row.length > 0) {
                var $price = $row.find('.js-price');
                var priceHtml = (value.price * value.qty).toFixed(2);
                if ($price.html().indexOf('$') !== -1)
                    priceHtml = '$' + priceHtml;

                $price.html(priceHtml);
            }
        });
    }
}

function getVariantIds()
{
    var variantIds = [];
    if ($variantSelects.length) {
        $variantSelects.each(function(index) {
            variantIds[index] = $(this).val();
        });
    }

    return variantIds;
}

function getVariants(string)
{
    var v = String(string);
    var variants = null;
    if (v.indexOf(',') > -1){
        variants = v.split(',')
    } else if (v != 'undefined' && v != null && v != '') {
        variants = [v];
    }

    return variants;
}
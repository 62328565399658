document.addEventListener("DOMContentLoaded", function() {
    document.getElementById("site-loader").classList.add('hide');
});

$(document).ready(function() {

    /*******************************************/
    //Close Promo and use sessionStorage to keep closed
    $('.js-close-promotion').on('click', function(e) {
        e.preventDefault();

        $('body').removeClass('has-promo');
        sessionStorage.setItem('haspromo', 'no');
    });

    if ( sessionStorage.getItem('haspromo') == 'no' ) {
        $('body').removeClass('has-promo');
    }

    $(window).scroll(function () {
        $('body').toggleClass("fixed-header", ($(window).scrollTop() > 121));
     });

    /*******************************************/
    //Form Validation
    $('.validate-form').each(function() {
        $(this).validate({
            submitHandler: function(form) {
                form.submit();
            },
            rules: {
                required: true
            },
            errorPlacement: function(error, element) {
                error.appendTo(element.parents('.form-field'));
            },
            highlight: function(element, errorClass, validClass) {
                if (element.type === 'radio') {
                    this.findByName(element.name).parents('.form-field').addClass(errorClass).removeClass(validClass);
                } else {
                    $(element).parents('.form-field').addClass(errorClass).removeClass(validClass);
                }
            },
            unhighlight: function(element, errorClass, validClass) {
                if (element.type === 'radio') {
                    this.findByName(element.name).parents('.form-field').removeClass(errorClass).addClass(validClass);
                } else {
                    $(element).parents('.form-field').removeClass(errorClass).addClass(validClass);
                }
            }
        });
    });


    var dropdownHeight = $('.products-dropdown').outerHeight();
    var headerHeight = $('.products-dropdown h3').outerHeight();

    if ( headerHeight  > dropdownHeight ) {
        // console.log(headerHeight, dropdownHeight, 'no');
        $('.products-dropdown h3').hide();
    }



    /*******************************************/
    //Close Menu when scrolling down
    $(window).scroll(function() {
        if ($(document).scrollTop() > 100) {
            slideMenu('close');
        }
        else {
            // console.log('top');
        }
    });

    /*******************************************/
    //Custom Select Dropdown 
    $(function() {
        $('.custom-select-dropdown').selectric();
    });

    /*******************************************/
    //Lightbox Initiations
    $('.open-popup-link').magnificPopup({
        type:'inline',
        showCloseBtn: true,
        closeOnBgClick: true,
        removalDelay: 300,
        // mainClass: 'mfp-zoom-in',
        midClick: true
    });

    $('.open-video-link').magnificPopup({
        type:'iframe',
        showCloseBtn: true,
        closeOnBgClick: true,
        removalDelay: 300,
        // mainClass: 'mfp-zoom-in',
        midClick: true
    });

    $('.lightbox-manual-close-trigger').click(function(event) {
        e.preventDefault();
        $.magnificPopup.close();
    });

    /*******************************************/
    // Tabs
    $('.js-tab-triggers a').on('click', function(e) {
        e.preventDefault();
        $('.js-tab-triggers a').not(this).removeClass('active');
        $(this).addClass('active');
        var getTarget = $(this).data('href');
        $('.tab-content').not(getTarget).removeClass('active');
        $(getTarget).addClass('active');
    });

    /*******************************************/
    // Click anchor with # to scroll to id
    $('a[href^="#"]').on('click', function(e) {
        var target = $( $(this).attr('href') );

        if( target.length ) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: target.offset().top
            }, 500);
        }

    });

    /*******************************************/
    // Header Filter Toggle
    $('.js-toggle-filter').on('click', function(e) {
        e.preventDefault();
        slideMenu('close');
        $('.sort').removeClass('active');
        $('.filters').toggleClass('active');
        $(this).toggleClass('active');

        //closeBox();
    });

    //closes filters when clicking somewhere else
    $("body").click(function(e) {

        if ( $('.js-toggle-filter').hasClass('active') ) {
            if( $(e.target).closest(".filters-module").length === 0 ){
                console.log('sadas');
                $('.filters').removeClass('active');
                $('.sort').removeClass('active');
                $('.js-toggle-filter').removeClass('active')
            }
        }
    });

    //closes products dropdown when clicking somewhere else
    $(document).mouseup(function(e) {
        var container = $(".products-dropdown");
        console.log(e.target);
        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            // $("body").removeClass('open-dropdown');
            // $('.products-dropdown').attr('status', 'closed');
            // $('.button-shop').html('SHOP NOW')
            dropdownMenu('close');
        } else if ( !container.is(e.target) && $('.button-shop').is(e.target)  ) {
            console.log('yo');
            dropdownMenu('close');
        }
    
    });

    /*******************************************/
    // Header Sort Toggle
    $('.js-sort-filter').on('click', function(e) {
        e.preventDefault();
        slideMenu('close');
        $('.filters').removeClass('active');
        $('.sort').toggleClass('active');
        $(this).toggleClass('active');
    });

    /*******************************************/
    // Header Products dropdown
    $('.js-toggle-search').on('click', function(e) {
        e.preventDefault();
        slideMenu('close');
        dropdownMenu('close');
        $('.products-dropdown').removeClass('active');
        $('.products-search').toggleClass('active');
    });

    /*******************************************/
    // Footer Accordion go to and Toggle
    $('.site-footer .col a').on('click', function(e) {
        // e.preventDefault();
        setTimeout(function() {
            var hash = window.location.hash;

            if (hash) {
                console.log(hash);
                $('html, body').animate({
                    scrollTop: $('.title-module').offset().top
                }, 500);
                $('.accordion-container article').removeClass('active');
                $(hash).parent().addClass('active');
            }
        }, 100);
    });

    /*******************************************/

    $('.js-toggle-children-mob').on('click', function(e) {
        $(this).parent().parent().find('.child').toggleClass('active');
    });

});


/*******************************************/
$.fn.toggleHTML = function(t1, t2) {
    if (this.html() == t1) this.html(t2);
    else this.html(t1);
    return this;
};


/*******************************************/
//Main Menu
function slideMenu(action){ 
    var $menu = $('#mobile-menu') 
    if (!action || action == "toggle") { toggle(); return }
    if (action == "open") { open(); return }
    if (action == "close") { close(); return }

    function open(){
        $("body").addClass('open-menu');
        $menu.attr('status', 'open');
        dropdownMenu('close');
        searchMenu('close');
    }
    function close(){
        $("body").removeClass('open-menu');
        $menu.attr('status', 'closed');
    }
    function toggle(){
        var status =  $menu.attr('status');
        if (status == "open"){ close(); return }
        if (status == "closed"){ open(); return }
    }
}

/*******************************************/
//Products Dropdown Menu
function dropdownMenu(action){ 
    var $menu = $('.products-dropdown') 
    if (!action || action == "toggle") { toggle(); return }
    if (action == "open") { open(); return }
    if (action == "close") { close(); return }

    function open(){
        $("body").addClass('open-dropdown');
        $menu.attr('status', 'open');
        $menu.addClass('open');
        slideMenu('close');
        searchMenu('close');
        // $('.button-shop').html('CLOSE MENU')
    }
    function close(){
        $("body").removeClass('open-dropdown');
        $menu.attr('status', 'closed');
        $menu.removeClass('closed');
        // $('.button-shop').html('SHOP NOW')
    }
    function toggle(){
        var status =  $menu.attr('status');
        if (status == "open"){ close(); return }
        if (status == "closed"){ open(); return }
    }
}

/*******************************************/
//Products Dropdown Menu
function searchMenu(action){ 
    var $menu = $('.products-search') 
    if (!action || action == "toggle") { toggle(); return }
    if (action == "open") { open(); return }
    if (action == "close") { close(); return }

    function open(){
        $("body").addClass('open-search');
        $menu.attr('status', 'open');
        slideMenu('close');
        dropdownMenu('close');
    }
    function close(){
        $("body").removeClass('open-search');
        $menu.attr('status', 'closed');
    }
    function toggle(){
        var status =  $menu.attr('status');
        if (status == "open"){ close(); return }
        if (status == "closed"){ open(); return }
    }
}

/*******************************************/
// Header Highlighter
$(document).ready(function() {
    var headerHref = "#site-header";
    var mobileHref = "#mobile-menu";
    var myURL = "/" + location.pathname.split('/')[1];

    $(headerHref + ' li').each(function(){
        if ($(this).find('a').attr('href') == myURL) {
            $(this).find('a').addClass("selected")
            return
        }
    })

    $(mobileHref + ' li').each(function(){
        if ($(this).find('a').attr('href') == myURL) {
            $(this).find('a').addClass("selected")
            return
        }
    })
});

/*******************************************/




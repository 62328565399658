/**
 * Locator global namespace object
 */
var locator = {};

locator = function()
{
    var pub = {};
    var self = {};
    var infoWindow;
    var markers = [];

    /**
     * Initialize locator
     */
    pub.init = function(options)
    {
        /**
         * Default values
         */
        var defaults = {
            default_lat: -41.475660,  //NZ
            default_lng: 173.144531,  //NZ            
            default_zoom: 4,
            mapname: 'map',
            formname: 'stockist-search',
            selectoption_name: 'browse',
            inputname: 'search',
            address_suffix: ''
        }
        
        infoWindow = new google.maps.InfoWindow();

        // options (object) coming from init() call
        pub.settings = $.extend({}, defaults, options);
        pub.settings.countdiv = document.getElementById('title1');
        pub.settings.searchtitle = document.getElementById('title2');
        pub.settings.sidebar = document.getElementById('scroll-pane');

        // Create geocoder object
        pub.geocoder = new google.maps.Geocoder();

        var myOptions = {
            center: new google.maps.LatLng(pub.settings.default_lat, pub.settings.default_lng),
            zoom: 0,
            streetViewControl: false,
            disableDefaultUI: true,
            minZoom: pub.settings.default_zoom,
            zoomControl: true,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
//            styles: mapstyle,
            scrollwheel: false
        };

        pub.map = new google.maps.Map(document.getElementById("map"), myOptions);
        pub.bindActions();
    }

    /**
     * Bind widget control actions
     */
    pub.bindActions = function()
    {
       // Bind search form
       $(document.getElementById(pub.settings.formname)).bind('submit', function(e){
           e.preventDefault();
           var inputval = document.getElementById(pub.settings.inputname).value;

           if(inputval.length == 3) {
               inputval = '0' + inputval;
           }
           var address = inputval + pub.settings.address_suffix;
           pub.searchLocations(address);
           $('#searchResult').slideDown('fast'); 
       });
       
       /**
        * for browse button to find stores by area
        */
       $(document.getElementById(pub.settings.selectoption_name)).bind('change', function(e){
           e.preventDefault();
           var slug = $(this).val();
           var address = slug + pub.settings.address_suffix;
           pub.searchLocations(address, slug); 
           $('#searchResult').slideDown('fast');            
       });
    }

    /**
     * Convert input into coordinates and run search
     */
    pub.searchLocations = function(address, slug)
    {
        // Pre-search, set loading display
        pub.settings.countdiv.innerHTML = 'Loading...';
        pub.settings.searchtitle.innerHTML = '';
        pub.settings.sidebar.innerHTML = '';

        pub.geocoder.geocode({'address': address}, function(results, status) {
            if(status == google.maps.GeocoderStatus.OK)
                // console.log(results[0].geometry.location);
                pub.searchLocationsNear(results[0].geometry.location, address, slug);
            else
                pub.settings.countdiv.innerHTML = address + ' not found.';
        });
    }

    /**
     * Search for locations near passed coordinates
     * Load map markers, format sidebar
     */
    pub.searchLocationsNear = function(center, address, slug)
    {
        pub.clearLocations();
        
        var radius = 50;
        // Build search locations url
        var searchUrl = 'http://glamuzina.co.nz/store/search?lat=' + center.lat() + '&lng=' + center.lng() + '&radius=' + radius;
        if(slug != undefined) searchUrl += '&slug=' + slug;

        pub.downloadUrl(searchUrl, function(data) {
            var xml = pub.parseXml(data);
            var markerNodes = xml.documentElement.getElementsByTagName('marker');

            // Clear map - we want to start with an empty map
            pub.clearLocations();

            // Clear sidebar
            if (markerNodes.length == 0) {
                pub.settings.countdiv.innerHTML = 'No results found.';
                return;
            }

            // Define map bounds
            var bounds = new google.maps.LatLngBounds();

            pub.settings.countdiv.innerHTML = 'Stores in your area';
            // pub.settings.searchtitle.innerHTML = address;
            for (var i = 0; i < markerNodes.length; i++) {
                var id = markerNodes[i].getAttribute('id');
                var name = markerNodes[i].getAttribute('name');
                var addr1 = markerNodes[i].getAttribute('address1');
                var addr2 = markerNodes[i].getAttribute('address2');
                var addr3 = markerNodes[i].getAttribute('address3');
                var addr4 = markerNodes[i].getAttribute('address4');
                var addr5 = markerNodes[i].getAttribute('address5');
                var phone = markerNodes[i].getAttribute('phone');
                var distance = parseFloat(markerNodes[i].getAttribute('distance'));
                var latlng = new google.maps.LatLng(parseFloat(markerNodes[i].getAttribute("lat")), parseFloat(markerNodes[i].getAttribute("lng")));

                var marker = pub.createMarker(latlng, name, addr1, addr2, addr3, addr4, addr5, phone);
                markers.push(marker);
                var sidebarEntry = pub.createSidebarEntry(marker, name, addr1, addr2, addr3, addr4, addr5, phone, distance, id);
                pub.settings.sidebar.appendChild(sidebarEntry);
                
                bounds.extend(latlng);
            }

            pub.map.fitBounds(bounds);
        });
    }
    
    
    /**
     * Create map marker at the specific point
     */
    pub.createMarker = function (latlng, name, addr1, addr2, addr3, addr4, addr5, phone)
    {
        var html = '';
        var marker = new google.maps.Marker({map: pub.map, position: latlng});
        
        html = html + '<strong>' + name + '</strong> <br/>' +
                   addr1 + '<br/>' + addr2 + '<br/>' + addr3 + '<br/>' + addr4 + '<br/>' + addr5 + '<br/>' +
                   phone;

        google.maps.event.addListener(marker, 'click', function() {
            infoWindow.setContent(html);
            infoWindow.open(pub.map, marker);
        });
        
        return marker;
    }

    pub.createSidebarEntry = function (marker, name, addr1, addr2, addr3, addr4, addr5, phone, distance, id)
    {
        var li = document.createElement('li');
        var html = '';

        html = '<div class="itemtitle"><strong>'+ name +'</strong></div>'+phone+'<br />'+addr1+'<br />'+addr2;
        html += (addr3.length > 0)?', ':'';
        html += addr3+'<br />'+addr4;
        html += (addr5.length > 0)?', ':'';
        html += addr5+'<br />'+ (Math.round(distance * 10) / 10) +'km&nbsp;</span><span><img src="/img/global/icons/arrow.png" /></span>';

        li.innerHTML = html;
//        li.className = "clearfix";
        google.maps.event.addDomListener(li, 'click', function() {
            google.maps.event.trigger(marker, 'click');
//            $.post('/store/index/update', { id: id });
        });
        return li;
    }

    pub.clearLocations = function() {
        infoWindow.close();
        for (var i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
        }
        markers.length = 0;
    }

    pub.downloadUrl = function(url,callback)
    {
        var request = window.ActiveXObject ? new ActiveXObject('Microsoft.XMLHTTP') : new XMLHttpRequest;

        request.onreadystatechange = function() {
            if (request.readyState == 4) {
                request.onreadystatechange = pub.doNothing;
                callback(request.responseText, request.status);
            }
        };

        request.open('GET', url, true);
        request.send(null);
    }
    
    pub.parseXml = function(str) {
        if (window.ActiveXObject) {
            var doc = new ActiveXObject('Microsoft.XMLDOM');
            doc.loadXML(str);
            return doc;
        } else if (window.DOMParser) {
            return (new DOMParser).parseFromString(str, 'text/xml');
        }
    }

    pub.doNothing = function() {}

    return pub;
}();